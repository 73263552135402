/* eslint-disable no-param-reassign */
import React, { FC, useEffect } from 'react';
import { Admin, fetchUtils, Resource } from 'react-admin';
import { AuthAPI } from 'api/payway/auth';
import { CustomLayout } from 'layout/LayoutView';
import administrators from 'entities/administrators';
import clients from 'entities/clients';
import settings from 'entities/settings';
import MyNotification from 'notification';
import { dataProvider } from 'api/payway';
import { tokenFieldName } from 'constants/auth';
import { useNavigate } from 'react-router-dom';

export const httpClient = (url: string, options: any = {}): Promise<{
  status: number,
  headers: Headers,
  body: string,
  json: any
}> => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem(tokenFieldName);

  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`);
  }

  return fetchUtils.fetchJson(url, options);
};

export const AdminView: FC = () => {
  const token = localStorage.getItem(tokenFieldName);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token]);

  return (
    <Admin
      notification={MyNotification} dataProvider={dataProvider} authProvider={AuthAPI} layout={CustomLayout}
    >
      <Resource name="admin" {...administrators} />
      <Resource name="clients" {...clients} />
      <Resource name="settings" {...settings} />
    </Admin>
  );
};

export default AdminView;
