import RestAPI from 'api/rest';
import { tokenFieldName, user } from 'constants/auth/index';

export const AuthAPI = {
  login: async ({ username, password }: { username: string; password: string }) => {
    try {
      const { data } = await RestAPI.post('/api/admin/signIn', { login: username, password });
      if (data && data.token) {
        localStorage.setItem(tokenFieldName, data.token);
        localStorage.setItem(user, JSON.stringify(data.admin));
        return;
      }
      throw new Error('No token found');
    } catch (error) {
      Promise.reject(error);
    }
  },

  logout: () => {
    localStorage.removeItem(tokenFieldName);
    localStorage.removeItem(user);
    return Promise.resolve();
  },

  checkError: (error: any) => {
    const status = error.status || error.response?.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem(tokenFieldName);
      localStorage.removeItem(user);
      return Promise.reject();
    }
    return Promise.resolve();
  },

  checkAuth: () => localStorage.getItem(tokenFieldName)
    ? Promise.resolve()
    : Promise.reject({ redirectTo: '/login' }),

  getPermissions: () => Promise.resolve(),

  getIdentity: () => {
    try {
      const userData = JSON.parse(localStorage.getItem(user) || '');
      return Promise.resolve({ id: userData.id, fullName: userData?.login, avatar: userData?.login.slice(0, 1) });
    } catch (error) {
      return Promise.reject(error);
    }
  }
};